const articlePrompt =
    "Generate a highly optimized, SEO-friendly, long-form article strictly following the given instructions. Ensure that ALL body terms are included at least 3 times. The article must be structured logically, and formatted in HTML. Each section should be generated separately to avoid truncation. The final output must be a valid JSON object with proper escaping of newline characters and double quotes.";
const mcqPrompt =
    "You are an AI expert in generating high-quality multiple-choice questions (MCQs) formatted for **Moodle XML import**. Your task is create multiple-choice questions.";
const bulletArticlePrompt =
    "Generate a **highly optimized, SEO-friendly, bullet-point style article** strictly following the given instructions. The article should be **structured with a clear hierarchy**, using **main points and sub-points**, and formatted correctly in HTML.";
const trueFalseQuestionPrompt =
    "You are an AI specialized in generating **True/False questions** formatted in **Moodle XML**. Your task is to generate well-structured True/False questions.";
const matchingQuestionPrompt =
    "You are an AI specialized in generating **Matching Questions** formatted in **Moodle XML**. Your task is to generate well-structured **matching questions**.";
const shortAnswerQuestionPrompt =
    "You are an AI specialized in generating **Short Answer Questions** formatted in **Moodle XML**. Your task is to generate well-structured short-answer questions."

export const contentTypes = [
    { value: "seo_article", label: "SEO Optimized Article", prompt: articlePrompt },
    { value: "article_para", label: "Article (paragraph)", prompt: articlePrompt },
    { value: "article_bullet", label: "Article (bullet points)", prompt: bulletArticlePrompt },
    { value: "multichoice", label: "MCQ", prompt: mcqPrompt },
    { value: "true_or_false", label: "True/False Questions", prompt: trueFalseQuestionPrompt },
    { value: "matching", label: "Matching Questions", prompt: matchingQuestionPrompt },
    { value: "short_answer", label: "Short Answer Questions", prompt: shortAnswerQuestionPrompt },
];

export function isQuestionsType(contentType) {
    return contentType === "MCQ"
        || contentType === "multichoice"
        || contentType === "true_or_false"
        || contentType === "matching"
        || contentType === "short_answer";
}

export function isArticleType(contentType) {
    return contentType === "SEO Optimized Article" ||
        contentType === "Article" ||
        contentType === "article_bullet" ||
        contentType === "article_para" ||
        contentType === "seo_article";
}

export function isNonSeoArticleType(contentType) {
    return contentType === "Article" ||
        contentType === "article_bullet" ||
        contentType === "article_para";
}

export function isSeoArticleType(contentType) {
    return contentType === "SEO Optimized Article" ||
        contentType === "seo_article";
}

export function isContentTypeSimilar(contentType, contentType2) {
    if (!contentType || !contentType2) {
        return false;
    }

    return (isArticleType(contentType) && isArticleType(contentType2)) ||
        (isQuestionsType(contentType) && isQuestionsType(contentType2));

}