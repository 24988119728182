import React, { useEffect, useMemo } from 'react';
import { Accordion, Nav, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { hasKeys, isTeacherRole } from '../shared/utils';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const Sidebar = ({ isOpen, setIsSidebarOpen, toggleSidebar, location, params }) => {
    const navigate = useNavigate();
    const rotateStyle = {
        transform: !isOpen ? 'rotate(180deg)' : 'none',
        transition: 'transform 0.3s ease-in-out', // Optional: Adding transition for a smooth effect
    };
    const imageSize = isOpen ? 110 : 52 // Default size: 50px
    const imageStyle = {
        borderRadius: '50%',
        width: imageSize,
        height: imageSize,
        objectFit: 'cover', // Ensure the image covers the entire container
    };
    useEffect(() => {
        let formData_1 = localStorage.getItem('tokenData')
        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?moodlewsrestformat=json&wsfunction=core_webservice_get_site_info', formData_1)
            .then(userdata => {
                if (!userdata.data.userid) {
                    localStorage.setItem('login', false)
                    navigate('/');
                }
            })
            .catch(error => {
                console.log(error)
            });
    }, [])
    const sideBarActive = useMemo(() => {
        if (hasKeys(params) && hasKeys(params.action)) {
            return params.action
        }
        else {
            return ''
        }
    }, [location, params]);

    const isTeacher = useMemo(() => isTeacherRole(), []);

    return (
        <div className={`sidebar p-2 ${isOpen ? 'sidebar-open' : 'close'}`}>
            <div className="sidebar-content">
                {!isTeacher && <Accordion className={`accordion-box ${isOpen ? 'maximized' : 'minimized'}`}>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className="align-items-center">
                            <div className={`${isOpen ? 'me-2' : 'me-0'} ${isOpen ? '' : 'mx-auto'}`}>
                                <img src={"/images/videoFinderIcon.svg"} alt='video' />
                            </div>
                            {isOpen && <div>Video Finder</div>}
                        </Accordion.Header>
                        <div className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}>
                            <Accordion.Body>
                                {isOpen && <Nav className="flex-column accordion-menu-wrapper">
                                    <Nav.Link active={sideBarActive == 'search'} as={Link} to="/home/search">Search</Nav.Link>
                                    <Nav.Link active={sideBarActive == 'replace-search'} as={Link} to="/home/replace-search">Replace Search</Nav.Link>
                                    <Nav.Link active={sideBarActive == 'videos'} as={Link} to="/home/videos">Videos</Nav.Link>
                                    <Nav.Link active={sideBarActive == 'settings'} as={Link} to="/home/settings">Settings</Nav.Link>
                                    {/* <Nav.Link active={sideBarActive == 'summary-generation'} as={Link} to="/home/summary-generation">Summary Generation</Nav.Link> */}
                                </Nav>}
                            </Accordion.Body>
                        </div>
                    </Accordion.Item>
                </Accordion>}
                <Accordion className={`accordion-box ${isOpen ? 'maximized' : 'minimized'} mt-2`}>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className="align-items-center">
                            <div className={`${isOpen ? 'me-2' : 'me-0'} ${isOpen ? '' : 'mx-auto'}`}>
                                <img src={"/images/video-guide.svg"} alt='video' />
                            </div>
                            {isOpen && <div>Video Guide</div>}
                        </Accordion.Header>
                        <div className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}>
                            <Accordion.Body>
                                {isOpen && <Nav className="flex-column accordion-menu-wrapper">
                                    {!isTeacher && <Nav.Link active={sideBarActive == 'video-guide-default-settings'} as={Link} to="/home/video-guide-default-settings">Settings</Nav.Link>}
                                    <Nav.Link active={sideBarActive == 'video-guide-settings'} as={Link} to="/home/video-guide-settings">Search</Nav.Link>
                                    <Nav.Link active={sideBarActive == 'video-guide-report'} as={Link} to="/home/video-guide-report">Report</Nav.Link>
                                </Nav>}
                            </Accordion.Body>
                        </div>
                    </Accordion.Item>
                </Accordion>
                {!isTeacher && <Accordion className={`accordion-box ${isOpen ? 'maximized' : 'minimized'} mt-2`}>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className="align-items-center">
                            <div className={`${isOpen ? 'me-2' : 'me-0'} ${isOpen ? '' : 'mx-auto'}`}>
                                <img src={"/images/scribe.svg"} alt='video' />
                            </div>
                            {isOpen && <div>Scribe AI Content</div>}
                        </Accordion.Header>
                        <div className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}>
                            <Accordion.Body>
                                {isOpen && <Nav className="flex-column accordion-menu-wrapper">
                                    <Nav.Link active={sideBarActive == 'scribe-content-generate'} as={Link} to="/home/scribe-content-generate">Generate</Nav.Link>
                                    <Nav.Link active={sideBarActive == 'scribe-contents'} as={Link} to="/home/scribe-contents">Generated content</Nav.Link>
                                </Nav>}
                            </Accordion.Body>
                        </div>
                    </Accordion.Item>
                </Accordion>}
                <div className='sidebar-bottum'>
                    <div className='dot-image'>
                        <Image src={sideBarActive.includes('scribe') ? "/images/scribe-dog.png" : "/images/Dot.png"} alt="User Profile" style={imageStyle} />
                    </div>
                    <div className='panel-trigger'>
                        <Button variant='link' className='p-0' onClick={toggleSidebar}>
                            <img src={"/images/left_panel_open.svg"} style={rotateStyle} className='img-fluid' alt="panel" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
