import { isEmpty } from 'lodash';

export function hasKeys(obj) {
    if (!isEmpty(obj)) {
        return true
    }
    else {
        return false
    }
}

export function isTeacherRole() {
    return [2, 3].includes(localStorage.getItem('role_id'));
}