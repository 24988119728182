import axios from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import { Row, Col, Form, Button, Table, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  defaultGenerationPrompt,
  defaultValidationPrompt,
} from "../constants/scribe-ai-prompts";
import { useSearchParams } from "react-router-dom";
import ScribeContentDisplay from "../components/scribe-content-display";
import Papa from "papaparse";
import {
  contentTypes,
  isArticleType,
  isNonSeoArticleType,
  isQuestionsType,
  isSeoArticleType,
} from "../shared/scribe";
import ScribeContentModal from "../components/scribe-content-modal";

const steps = [
  { number: 1, title: "Input topic" },
  { number: 2, title: "Configuration" },
  { number: 3, title: "Generate" },
];

const ScribeContentGenerate = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentStep, _setCurrentStep] = useState(
    searchParams.get("id") ? 3 : 1
  );
  const setCurrentStep = async (step) => {
    if (currentStep === 1) {
      if (!listData.length && (!topic || !content)) {
        return toast.error("Please fill in all fields.");
      }
      if (!listData?.length && topic && content) {
        setListData([{ title: topic, content: content, keywords: "" }]);
      }
    }
    if (currentStep === 2) {
      if (
        !contentType ||
        !selectedGenerationModel?.value ||
        !selectedValidationModel?.value ||
        !generationPrompt ||
        !validationPrompt
      ) {
        return toast.error("Please fill in all fields.");
      }
      if (
        isNonSeoArticleType(contentType) &&
        (wordsCount < 200 || wordsCount > 6000)
      ) {
        return toast.error("Words count has to be between 200 and 6000.");
      }
      if (
        isQuestionsType(contentType) &&
        (questionsCount < 1 || questionsCount > 20)
      ) {
        return toast.error("Questions count has to be between 1 and 20.");
      }
      setIsSubmitting(true);
      try {
        const payload = {
          content_type: contentType,
          generation_model_id: selectedGenerationModel.value,
          generation_model_name: selectedGenerationModel.label,
          generation_prompt: generationPrompt,
          validation_model_id: selectedValidationModel.value,
          validation_model_name: selectedValidationModel.label,
          validation_prompt: validationPrompt,
          use_seo: useSeo,
          user_id: localStorage.getItem("user_id"),
          word_count: isNonSeoArticleType(contentType)
            ? wordsCount
            : isSeoArticleType(contentType)
            ? 6000
            : 0,
          question_count: isQuestionsType(contentType) ? questionsCount : 0,
          topics: listData,
        };
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/scribe-bot`,
          payload
        );
        setSearchParams((params) => {
          params.set("id", res.data.data);
          return params;
        });
      } catch (err) {
        toast.error("Failed to generate content. Please try again later.");
        console.error("Error generating content:", err);
        return;
      } finally {
        setIsSubmitting(false);
      }
    }
    _setCurrentStep(step);
  };

  const [topic, setTopic] = useState("");
  const [content, setContent] = useState("");
  const [listData, setListData] = useState([]);

  const [contentType, setContentType] = useState(contentTypes[0].value);
  const useSeo = useMemo(() => contentType === "seo_article");
  const [seoKeywords, setSeoKeywords] = useState("");
  const [wordsCount, setWordsCount] = useState(6000);
  const [questionsCount, setQuestionsCount] = useState(20);
  const [selectedGenerationModel, setSelectedGenerationModel] = useState();
  const [generationPrompt, setGenerationPrompt] = useState(
    defaultGenerationPrompt
  );
  const [selectedValidationModel, setSelectedValidationModel] = useState();
  const [validationPrompt, setValidationPrompt] = useState(
    defaultValidationPrompt
  );

  const fileInputRef = useRef(null);
  const [fileType, setFileType] = useState(null);

  const handleFileUpload = (type) => {
    setFileType(type);
    setTimeout(() => fileInputRef.current.click(), 0);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = async (e) => {
      try {
        if (fileType === "json") {
          const jsonContent = JSON.parse(e.target.result);
          setListData(jsonContent);
        } else if (fileType === "csv") {
          const csvContent = Papa.parse(e.target.result, { header: true });
          setListData(csvContent.data.filter((row) => !!row.title));
        }
      } catch (error) {
        console.error("Error processing file:", error);
        setContent(
          "Error processing file. Please ensure the file format is correct."
        );
      }
    };

    if (fileType === "json") {
      reader.readAsText(file);
    } else if (fileType === "csv") {
      reader.readAsText(file);
    }
  };

  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchModels = async () => {
      try {
        setLoading(true);
        const res = await axios.get("https://openrouter.ai/api/v1/models");
        const modelOptions = res.data.data.map((model) => ({
          value: model.id,
          label: model.name,
        }));
        setModels(modelOptions);
        if (!selectedGenerationModel)
          setSelectedGenerationModel(
            modelOptions.find(
              (m) => m.value === "anthropic/claude-3.5-sonnet-20240620:beta"
            )
          );
        if (!selectedValidationModel)
          setSelectedValidationModel(
            modelOptions.find((m) => m.value === "openai/chatgpt-4o-latest")
          );
      } catch (err) {
        toast.error("Failed to load models. Please try again later.");
        console.error("Error fetching models:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchModels();
  }, []);

  // Poll api to get status every 2 seconds
  const [responseList, setResponseList] = useState([]);
  useEffect(() => {
    let interval;
    const id = searchParams.get("id");
    if (
      currentStep === 3 &&
      (!responseList.length ||
        responseList.some((r) => r.status !== "VALIDATION_COMPLETED")) &&
      id
    ) {
      let search = `ids=[${id}]`;
      interval = setInterval(async () => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/scribe-bot?${search}`
          );
          setResponseList(res.data.data);
        } catch (err) {
          console.error("Error fetching status:", err);
        }
      }, 2000);
    } else if (interval) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [currentStep, responseList]);

  const [downloadingType, setDownloadingType] = useState();
  const downloadItems = (type, id) => {
    setDownloadingType(type);
    const url = `${process.env.REACT_APP_API_URL}/scribe-bot`;
    const params = new URLSearchParams({
      ids: `[${id}]`,
      output_format: type,
    });
    axios
      .put(`${url}?${params.toString()}`, undefined, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(({ data }) => {
        const fileName = data.data;
        const fileUrl = `https://svhs-scribe-ai.s3.us-east-2.amazonaws.com/${fileName}`;
        fetch(fileUrl, { method: "GET" })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          });
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        toast.error("Error downloading file");
      })
      .finally(() => {
        setDownloadingType(false);
      });
  };

  const [viewId, setViewId] = useState();
  const selectedData = useMemo(
    () => responseList?.find((d) => d.id === viewId),
    [responseList, viewId]
  );

  const [retryingIds, setRetryingIds] = useState([]);
  const retry = (ids) => {
    setRetryingIds(ids);
    const url = `${process.env.REACT_APP_API_URL}/scribe-bot-publish`;
    const params = new URLSearchParams({ ids: `[${ids}]` });
    axios
      .get(`${url}?${params.toString()}`, undefined, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(({ data }) => {})
      .catch((error) => {
        console.error("Error retrying:", error);
        toast.error("Error retrying");
      })
      .finally(() => {
        setRetryingIds((prev) => prev.filter((id) => !ids.includes(id)));
      });
  };

  return (
    <>
      <Row className="mt-2 mt-4 mx-4">
        {steps.map((step) => (
          <Col
            key={step.number}
            className="text-center d-flex align-items-center gap-2"
          >
            <div
              className={`rounded-circle d-inline-flex align-items-center justify-content-center`}
              style={{
                width: "2.5rem",
                height: "2.5rem",
                border: "2px solid",
                borderColor: step.number <= currentStep ? "#0d6efd" : "#dee2e6",
                color: step.number <= currentStep ? "#0d6efd" : "#6c757d",
              }}
            >
              {step.number}
            </div>
            <div
              className={
                step.number <= currentStep ? "text-primary" : "text-muted"
              }
            >
              {step.title}
            </div>
          </Col>
        ))}
      </Row>
      <div className="m-4">
        {currentStep === 1 && (
          <div className="d-flex flex-column gap-2">
            <div className="d-flex gap-2">
              {!listData.length && (
                <input
                  type="text"
                  className="form-control"
                  style={{ width: "fit-content", minWidth: "400px" }}
                  placeholder="Enter topic"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                />
              )}
              <Button
                variant="secondary"
                className="ms-auto"
                onClick={() => handleFileUpload("csv")}
              >
                Upload CSV
              </Button>
              <Button
                variant="secondary"
                onClick={() => handleFileUpload("json")}
              >
                Upload JSON
              </Button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept={fileType === "json" ? ".json" : ".csv"}
                onChange={handleFileChange}
              />
            </div>
            {!listData.length && (
              <textarea
                className="form-control"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                style={{ width: "100%" }}
                rows={10}
                placeholder="Enter content"
              />
            )}
            {!!listData.length && (
              <Table responsive className="scribe-contents-table">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Content</th>
                    <th>Keywords</th>
                  </tr>
                </thead>
                <tbody>
                  {listData?.map((row, i) => (
                    <tr key={i}>
                      <td>{row.title}</td>
                      <td>{row.content}</td>
                      <td>{row.keywords}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            <Button
              className="ms-auto"
              style={{ width: "fit-content" }}
              onClick={() => setCurrentStep(2)}
            >
              Next
            </Button>
          </div>
        )}
        {currentStep === 2 && (
          <div className="d-flex flex-column gap-2 text-start">
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Content type</Form.Label>
                  <Form.Select
                    value={contentType}
                    onChange={(e) => {
                      setContentType(e.target.value);
                      setGenerationPrompt(
                        contentTypes.find((m) => m.value === e.target.value)
                          .prompt
                      );
                    }}
                  >
                    <option value="" disabled>
                      -- Please choose an option --
                    </option>
                    {contentTypes.map((m) => (
                      <option value={m.value}>{m.label}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                {isSeoArticleType(contentType) && !listData.length && (
                  <Form.Group>
                    <Form.Label>SEO Keywords</Form.Label>
                    <Form.Control
                      value={seoKeywords}
                      onChange={(e) => setSeoKeywords(e.target.value)}
                    />
                  </Form.Group>
                )}
                {isNonSeoArticleType(contentType) && (
                  <Form.Group>
                    <Form.Label>Words Count</Form.Label>
                    <Form.Control
                      value={wordsCount}
                      onChange={(e) => setWordsCount(e.target.value)}
                      type="number"
                    />
                  </Form.Group>
                )}
                {isQuestionsType(contentType) && (
                  <Form.Group>
                    <Form.Label>Questions Count</Form.Label>
                    <Form.Control
                      value={questionsCount}
                      onChange={(e) => setQuestionsCount(e.target.value)}
                      type="number"
                    />
                  </Form.Group>
                )}
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Generation LLM</Form.Label>
                  <Form.Select
                    value={selectedGenerationModel?.value}
                    onChange={(e) => {
                      setSelectedGenerationModel(
                        models.find((m) => m.value === e.target.value)
                      );
                    }}
                  >
                    <option value="" disabled>
                      {loading ? "Loading" : "-- Please choose an option --"}
                    </option>
                    {!loading &&
                      models.map((m) => (
                        <option value={m.value}>{m.label}</option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Validation LLM</Form.Label>
                  <Form.Select
                    value={selectedValidationModel?.value}
                    onChange={(e) => {
                      setSelectedValidationModel(
                        models.find((m) => m.value === e.target.value)
                      );
                    }}
                  >
                    <option value="" disabled>
                      {loading ? "Loading" : "-- Please choose an option --"}
                    </option>
                    {!loading &&
                      models.map((m) => (
                        <option value={m.value}>{m.label}</option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Generation Prompt</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Type your prompt here..."
                    value={generationPrompt}
                    onChange={(e) => setGenerationPrompt(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Validation Prompt</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Type your prompt here..."
                    value={validationPrompt}
                    onChange={(e) => setValidationPrompt(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button
              className="ms-auto"
              style={{ width: "fit-content" }}
              onClick={() => setCurrentStep(3)}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Please wait" : "Next"}
            </Button>
          </div>
        )}
        {currentStep === 3 && responseList.length === 1 && (
          <div className="d-flex flex-column gap-2">
            <div
              style={{
                border: "1px solid #bbb",
                borderRadius: "8px",
                padding: "6px 12px",
                textAlign: "left",
              }}
            >
              {useSeo || responseList[0].useSeo ? (
                <div className="d-flex gap-2 my-2">
                  <StatusIndicator
                    status={
                      responseList[0].status === "SEO_OPTIMISATION_INPROGRESS"
                        ? "inprogress"
                        : "completed"
                    }
                  />
                  Scraping SEO data for topic: {topic || responseList[0].title}
                </div>
              ) : (
                <div className="d-flex gap-2 my-2">
                  <StatusIndicator status="completed" />
                  Generating content generation for topic:{" "}
                  {topic || responseList[0].title}
                </div>
              )}
              <div className="d-flex gap-2 my-2">
                <StatusIndicator
                  status={
                    responseList[0].status === "GENERATION_INPROGRESS"
                      ? "inprogress"
                      : responseList[0].status === "GENERATION_ERROR"
                      ? "error"
                      : ["NOT_STARTED", "SEO_OPTIMISATION_INPROGRESS"].includes(
                          responseList[0].status
                        )
                      ? "pending"
                      : "completed"
                  }
                />
                Generating content using model: {selectedGenerationModel?.label}
              </div>
              <div className="d-flex gap-2 my-2">
                <StatusIndicator
                  status={
                    responseList[0].status === "VALIDATION_INPROGRESS"
                      ? "inprogress"
                      : responseList[0].status === "VALIDATION_ERROR"
                      ? "error"
                      : [
                          "NOT_STARTED",
                          "GENERATION_INPROGRESS",
                          "GENERATION_ERROR",
                          "SEO_OPTIMISATION_INPROGRESS",
                        ].includes(responseList[0].status)
                      ? "pending"
                      : "completed"
                  }
                />
                Validating content using model: {selectedValidationModel?.label}
              </div>
            </div>
            {!!responseList[0].error && (
              <div
                style={{
                  border: "1px solid red",
                  color: "red",
                  borderRadius: "8px",
                  padding: "6px 12px",
                  textAlign: "left",
                }}
              >
                Error: {responseList[0].error}
              </div>
            )}
            {["GENERATION_ERROR", "VALIDATION_ERROR"].includes(
              responseList[0].status
            ) && (
              <Button
                style={{
                  width: "fit-content",
                  marginBottom: ".5rem",
                }}
                onClick={() => retry([responseList[0].id])}
                disabled={!!retryingIds.length}
              >
                {retryingIds.length ? "Retrying..." : "Retry"}
              </Button>
            )}
            {responseList[0].status === "VALIDATION_COMPLETED" && (
              <Button
                style={{ width: "fit-content" }}
                onClick={() => setViewId(responseList[0].id)}
              >
                View Generated Content
              </Button>
            )}
          </div>
        )}
        {currentStep === 3 && responseList.length > 1 && (
          <div className="text-start">
            {responseList.some((row) =>
              ["GENERATION_ERROR", "VALIDATION_ERROR"].includes(row.status)
            ) && (
              <Button
                style={{
                  width: "fit-content",
                  marginBottom: ".5rem",
                }}
                onClick={() =>
                  retry(
                    responseList
                      .filter((row) =>
                        ["GENERATION_ERROR", "VALIDATION_ERROR"].includes(
                          row.status
                        )
                      )
                      .map((r) => r.id)
                  )
                }
                disabled={!!retryingIds.length}
              >
                {retryingIds.length
                  ? "Retrying failed contents..."
                  : "Retry failed contents"}
              </Button>
            )}
            <Table responsive className="scribe-contents-table">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Status</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {responseList?.map((row, i) => (
                  <tr key={i}>
                    <td>{row.title}</td>
                    <td>
                      <div
                        className="d-flex gap-2 align-items-center"
                        style={{ lineHeight: "1" }}
                      >
                        {!!(useSeo || row.useSeo) &&
                          row.status === "SEO_OPTIMISATION_INPROGRESS" && (
                            <>
                              <StatusIndicator status={"inprogress"} />
                              Scraping SEO data
                            </>
                          )}
                        {row.status === "GENERATION_INPROGRESS" && (
                          <>
                            <StatusIndicator status={"inprogress"} />
                            Generating content
                          </>
                        )}
                        {row.status === "GENERATION_ERROR" && (
                          <>
                            <StatusIndicator status={"error"} />
                            Error generating content
                          </>
                        )}
                        {row.status === "VALIDATION_INPROGRESS" && (
                          <>
                            <StatusIndicator status={"inprogress"} />
                            Validating content
                          </>
                        )}
                        {row.status === "VALIDATION_ERROR" && (
                          <>
                            <StatusIndicator status={"error"} />
                            Error validating content
                          </>
                        )}
                        {row.status === "NOT_STARTED" && (
                          <>
                            <StatusIndicator status={"pending"} />
                            Not started
                          </>
                        )}
                        {row.status === "VALIDATION_COMPLETED" && (
                          <>
                            <StatusIndicator status={"completed"} />
                            Completed
                          </>
                        )}
                      </div>
                    </td>
                    <td>
                      {["GENERATION_ERROR", "VALIDATION_ERROR"].includes(
                        row.status
                      ) ? (
                        retryingIds.includes(row.id) ? (
                          "Retrying"
                        ) : (
                          <a href="#" onClick={() => retry([row.id])}>
                            Retry
                          </a>
                        )
                      ) : (
                        <img
                          style={{ cursor: "pointer" }}
                          src="/images/eye.svg"
                          alt=""
                          onClick={() => setViewId(row.id)}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        <ScribeContentModal
          show={!!viewId}
          onHide={() => setViewId()}
          data={selectedData}
          onDownload={(type) => {
            downloadItems(type, viewId);
          }}
          isDownloading={!!downloadingType}
          onContentSave={(editContent) =>
            setResponseList((list) =>
              list.map((item) =>
                item.id === viewId
                  ? { ...item, generated_content: editContent }
                  : item
              )
            )
          }
        />
      </div>
    </>
  );
};

const StatusIndicator = ({ status }) => {
  if (status === "completed") {
    return (
      <div style={{ width: "24px", textAlign: "center" }}>
        <img src="/images/tick.svg" style={{ width: "14px" }} />
      </div>
    );
  }

  if (status === "inprogress") {
    return (
      <div style={{ width: "24px", textAlign: "center" }}>
        <img src="/images/loader.svg" style={{ width: "20px" }} />
      </div>
    );
  }

  if (status === "pending") {
    return (
      <div style={{ width: "24px", textAlign: "center" }}>
        <img
          src="/images/circle-dashed.svg"
          style={{ width: "16px", opacity: 0.4 }}
        />
      </div>
    );
  }

  if (status === "error") {
    return (
      <div style={{ width: "24px", textAlign: "center", color: "red" }}>
        <img src="/images/x-red.svg" style={{ width: "20px", opacity: 0.8 }} />
      </div>
    );
  }
};

export default ScribeContentGenerate;
