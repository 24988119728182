import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import ReactPlayer from "react-player";
import TranscriptTable from "../components/transcript-table";
import ViewQuestionsModal from "../components/view-questions-modal";
import { toast } from "react-toastify";
import EditPromptModal from "../components/edit-prompt-modal";
import ContentTable from "../components/content-table";
import { isTeacherRole } from "../shared/utils";

const VideoGuideSettings = () => {
  const [sourceType, _setSourceType] = useState("course-content");
  const setSourceType = useCallback((val) => {
    setVideoData();
    _setSourceType(val);
    setUrlInputValue("");
    setSelectedSource({
      lesson: "",
      course: "",
      unit: "",
      url: "",
    });
    setCurrentVideoCode("");
  }, []);

  const [urlInputValue, setUrlInputValue] = useState("");
  const [selectedSource, setSelectedSource] = useState({
    lesson: "",
    course: "",
    unit: "",
    url: "",
  });

  const [currentVideoCode, setCurrentVideoCode] = useState("");
  const [tableView, setTableView] = useState("");

  const [courseArray, setCourseArray] = useState([]);
  const [unitArray, setUnitArray] = useState([]);
  const [lessonArray, setLessonArray] = useState([]);
  const [urlArray, setUrlArray] = useState([]);

  const [videoData, setVideoData] = useState();
  const [loading, setLoading] = useState(false);
  const searchVideo = useCallback(async () => {
    const videoCode =
      sourceType === "course-content"
        ? selectedSource.url
        : extractYouTubeVideoIds(urlInputValue);

    setVideoData();
    setLoading(true);
    try {
      const { data } = await axios.get(
        process.env.REACT_APP_API_URL +
          `/video_guide/chapter_generate?video_url=${toVideoUrl(videoCode)}`
      );
      if (data.success) {
        setVideoData({
          added: data.message["queue status"] === "Completed",
          published: +data.message.publish === 1,
          videoCode,
          queueStatus: data.message["queue status"],
          message: data.message.message,
        });
      }
    } catch (err) {
      if (
        err?.response?.data?.success === false &&
        err.response.data.message["queue status"] !== "Started"
      ) {
        if (sourceType === "url") {
          const { data } = await axios.post(
            "https://" +
              process.env.REACT_APP_HOST +
              "/webservice/rest/server.php?wstoken=" +
              process.env.REACT_APP_TOKEN +
              "&wsfunction=local_course_service_getvideodetails&moodlewsrestformat=json&url=" +
              toVideoUrl(videoCode)
          );
          let dataArr = [];
          try {
            dataArr = Object.values(JSON.parse(data.data));
          } catch (err) {}
          if (dataArr.length) {
            const urlData = dataArr[0];
            setVideoData({
              added: false,
              published: false,
              videoCode,
              courseId: urlData.course_id,
              unitId: urlData.unit_id,
              lessonId: urlData.lesson_id,
            });
          } else {
            toast.error("Video does not exist in the database");
          }
        } else {
          setVideoData({
            added: false,
            published: false,
            videoCode,
            courseId: selectedSource.course,
            unitId: selectedSource.unit,
            lessonId: selectedSource.lesson,
          });
        }
      } else if (err?.response?.data?.message["queue status"] === "Started") {
        const { data } = err.response;
        setVideoData({
          added: data.message["queue status"] === "Completed",
          published: +data.message.publish === 1,
          videoCode,
          queueStatus: data.message["queue status"],
        });
      }
      console.log(err);
    } finally {
      setLoading(false);
      setEnableChapterRegeneration(false);
    }
  }, [selectedSource, urlInputValue]);

  const [addingVideo, setAddingVideo] = useState(false);
  const addVideo = useCallback(() => {
    if (!videoData) return;
    console.log(videoData);

    setAddingVideo(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/video_guide/chapter_generate`, {
        video_url: toVideoUrl(videoData.videoCode),
        course_id: videoData.courseId,
        unit_id: videoData.unitId,
        activity_id: videoData.lessonId,
      })
      .then(({ data }) => {
        setVideoData((prev) => ({
          ...prev,
          added: false,
          queueStatus: "Started",
        }));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setAddingVideo(false);
      });
  }, [videoData]);

  const [publishingVideo, setPublishingVideo] = useState(false);
  const publishVideo = useCallback(
    (status) => {
      if (!videoData) return;
      setPublishingVideo(true);
      axios
        .put(`${process.env.REACT_APP_API_URL}/video_guide/chapter_generate`, {
          video_url: toVideoUrl(videoData.videoCode),
          publish: status,
        })
        .then(({ data }) => {
          setVideoData((prev) => ({
            ...prev,
            published: status,
          }));
          searchVideo();
          toast.success(
            status
              ? "Successfully added a video guide link on the course unit page."
              : "Successfully removed the video guide link on the course unit page."
          );
        })
        .catch((err) => {
          console.log(err);
          toast.error(
            `Error occurred while ${
              status ? "publishing" : "unpublishing"
            } the video guide.`
          );
        })
        .finally(() => {
          setPublishingVideo(false);
        });
    },
    [videoData]
  );

  const validateUnits = (data) => {
    const temp = data.slice();
    const val = temp.filter(
      (unit) => unit.name.trim() !== "" && unit.name != "."
    );
    return val;
  };

  const [showQuestionsModal, setShowQuestionsModal] = useState(false);
  const [showPromptsModal, setPromptsModal] = useState(false);

  const isTeacher = useMemo(() => isTeacherRole(), []);

  useEffect(() => {
    let url =
      "https://" +
      process.env.REACT_APP_HOST +
      "/webservice/rest/server.php?wstoken=" +
      process.env.REACT_APP_TOKEN +
      "&wsfunction=local_course_service_course_data&moodlewsrestformat=json";

    if (isTeacher) {
      const userId = localStorage.getItem("user_id");
      url += "&user_id=" + userId;
    }

    axios
      .post(url)
      .then((response) => {
        setCourseArray(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    if (!selectedSource.course) return;
    axios
      .post(
        "https://" +
          process.env.REACT_APP_HOST +
          "/webservice/rest/server.php?wstoken=" +
          process.env.REACT_APP_TOKEN +
          "&wsfunction=local_course_service_course_unit_data&moodlewsrestformat=json&course_id=" +
          selectedSource.course
      )
      .then((response) => {
        if (response.data) {
          const decodedData = JSON.parse(response.data.data);
          let units = decodedData[0].units;
          setUnitArray(validateUnits(units));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedSource.course]);
  useEffect(() => {
    if (!selectedSource.unit) return;
    axios
      .post(
        "https://" +
          process.env.REACT_APP_HOST +
          "/webservice/rest/server.php?wstoken=" +
          process.env.REACT_APP_TOKEN +
          "&wsfunction=local_course_service_unit_lessions_data&moodlewsrestformat=json&unit_id=" +
          selectedSource.unit
      )
      .then((response) => {
        if (response.data) {
          setLessonArray(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedSource.unit]);
  useEffect(() => {
    if (!selectedSource.lesson) return;
    const lesson = lessonArray.find(
      (l) => l.Id.toString() === selectedSource.lesson
    );
    if (!lesson) return;
    const urls = extractYouTubeVideoIds(lesson.Lession_Content);
    setUrlArray(urls);
  }, [selectedSource.lesson]);

  const [regeneratingChapter, setRegeneratingChapter] = useState(false);
  const regenerateChapter = useCallback(
    (videoCode) => {
      let url = "";
      if (tableView === "transcript") {
        url = `${process.env.REACT_APP_API_URL}/video_guide_re_chapter`;
      } else if (tableView === "content") {
        url = `${process.env.REACT_APP_API_URL}/video_guide`;
      }
      if (!url) return;
      setRegeneratingChapter(true);
      axios
        .put(url, { video_url: `https://www.youtube.com/embed/${videoCode}` })
        .then((response) => {
          toast.success("Regeneration successful. Check new questions.");
          searchVideo();
          setCurrentVideoCode("");
          setTimeout(() => setCurrentVideoCode(videoCode), 1000);
        })
        .catch((err) => {
          toast.error("Error occured while regenerating chapter.");
          console.log(err);
        })
        .finally(() => {
          setRegeneratingChapter(false);
        });
    },
    [tableView, searchVideo]
  );

  const ref = useRef();
  const scrollRef = useRef();

  const [enableChapterRegeneration, setEnableChapterRegeneration] =
    useState(false);

  const [playing, setPlaying] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const timeout = useRef();

  return (
    <>
      <Container className="my-3">
        <div className="d-flex gap-4 mb-2 align-items-center">
          <Form.Check
            type="radio"
            name="sourceType"
            checked={sourceType === "url"}
            onChange={(e) => setSourceType("url")}
            label="Direct URL"
          />
          <Form.Check
            type="radio"
            name="sourceType"
            checked={sourceType === "course-content"}
            onChange={(e) => setSourceType("course-content")}
            label="Lesson"
          />
          <div
            style={{
              textAlign: "left",
              alignItems: "center",
              marginLeft: "auto",
            }}
          >
            {videoData?.message ? (
              <>
                <span style={{ fontWeight: "bold" }}>Status: </span>
                {videoData?.message}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <Row ref={scrollRef} className="align-items-center">
          {sourceType === "url" && (
            <Col md="6" lg="8s">
              <Form.Group
                controlId="url-textbox"
                className="d-flex align-items-center pb-2"
              >
                <Form.Control
                  type="text"
                  placeholder="Enter URL"
                  style={{ maxWidth: "100%" }}
                  value={urlInputValue}
                  onChange={(e) => setUrlInputValue(e.target.value)}
                />
              </Form.Group>
            </Col>
          )}
          {sourceType === "course-content" && (
            <>
              <Col md="6" lg="4">
                <Form.Group className="py-1" controlId="source">
                  <Form.Select
                    value={selectedSource.course}
                    onChange={(e) =>
                      setSelectedSource((s) => ({
                        ...s,
                        course: e.target.value,
                      }))
                    }
                  >
                    <option>Select Course</option>
                    {!!courseArray &&
                      courseArray.map((course) => (
                        <option key={course.Course_ID} value={course.Course_ID}>
                          {course.Course_Name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md="6" lg="4">
                <Form.Group className="py-1" controlId="source">
                  <Form.Select
                    value={selectedSource.unit}
                    onChange={(e) =>
                      setSelectedSource((s) => ({ ...s, unit: e.target.value }))
                    }
                  >
                    <option>Select Unit</option>
                    {!!unitArray &&
                      unitArray.map((unit) => (
                        <option key={unit.id} value={unit.id}>
                          {unit.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md="6" lg="4">
                <Form.Group className="py-1" controlId="source">
                  <Form.Select
                    value={selectedSource.lesson}
                    onChange={(e) =>
                      setSelectedSource((s) => ({
                        ...s,
                        lesson: e.target.value,
                      }))
                    }
                  >
                    <option>Select Lesson</option>
                    {!!lessonArray &&
                      lessonArray.map((lesson) => (
                        <option key={lesson.Id} value={lesson.Id}>
                          {lesson.Lession_Name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md="6" lg="4">
                <Form.Group className="py-1" controlId="source">
                  <Form.Select
                    value={selectedSource.url}
                    onChange={(e) =>
                      setSelectedSource((s) => ({ ...s, url: e.target.value }))
                    }
                  >
                    <option>Select URL</option>
                    {!!urlArray &&
                      urlArray.map((url) => (
                        <option key={url} value={url}>
                          {url}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </>
          )}
          <Col md="6" lg="4" style={{ display: "flex", gap: "8px" }}>
            <Button
              variant="outline-dark"
              disabled={
                sourceType === "course-content"
                  ? !selectedSource.url
                  : !urlInputValue
              }
              onClick={() => searchVideo()}
              style={{ height: "48px" }}
            >
              Search
            </Button>
            <Button
              variant="outline-dark"
              onClick={() => {
                setSelectedSource({
                  lesson: "",
                  course: "",
                  unit: "",
                  url: "",
                });
                setUrlInputValue("");
                setVideoData();
              }}
              style={{ height: "48px" }}
            >
              Clear
            </Button>
          </Col>
        </Row>
        {loading && <div style={{ margin: "40px 0" }}> Loading</div>}
        {!!videoData && !videoData.added && (
          <div className="d-flex gap-2 mb-2">
            {videoData.queueStatus === "Started" ? (
              "The selected video is being prepared for the Video Guide, and backend AI processing has started. This process may take a few minutes. Once the status changes to 'Ready for Review', you can begin reviewing the content and questions."
            ) : (
              <Button
                variant="primary"
                onClick={() => {
                  addVideo();
                }}
                style={{ height: "48px" }}
                disabled={addingVideo || videoData.queueStatus === "Started"}
              >
                {"Add Video"}
              </Button>
            )}
          </div>
        )}
        {!!videoData && videoData.added && (
          <>
            <div className="d-flex gap-2 mt-1 mb-2 align-items-center">
              <Button
                variant="primary"
                onClick={() => {
                  if (sourceType === "course-content") {
                    setCurrentVideoCode(selectedSource.url);
                  } else {
                    const videoCodes = extractYouTubeVideoIds(urlInputValue);
                    if (videoCodes.length) setCurrentVideoCode(videoCodes[0]);
                  }
                  setTableView("transcript");
                }}
                disabled={
                  sourceType === "course-content"
                    ? !selectedSource.url
                    : !urlInputValue
                }
                style={{ height: "48px" }}
                className={tableView === "transcript" ? "btn-active" : ""}
              >
                Video Transcript
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  if (sourceType === "course-content") {
                    setCurrentVideoCode(selectedSource.url);
                  } else {
                    const videoCodes = extractYouTubeVideoIds(urlInputValue);
                    if (videoCodes.length) setCurrentVideoCode(videoCodes[0]);
                  }
                  setTableView("content");
                }}
                disabled={
                  sourceType === "course-content"
                    ? !selectedSource.url
                    : !urlInputValue
                }
                style={{ height: "48px" }}
                className={tableView === "content" ? "btn-active" : ""}
              >
                Chapter Content
              </Button>
              <Button
                variant="primary"
                onClick={() => setPromptsModal(true)}
                style={{ height: "48px" }}
                disabled={
                  isTeacher ||
                  (sourceType === "course-content"
                    ? !selectedSource.url
                    : !urlInputValue)
                }
              >
                Edit Prompt
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setShowQuestionsModal(true);
                }}
                style={{ height: "48px" }}
                disabled={
                  sourceType === "course-content"
                    ? !selectedSource.url
                    : !urlInputValue
                }
              >
                View Questions
              </Button>
              <Button
                variant="primary"
                onClick={() =>
                  regenerateChapter(
                    sourceType === "course-content"
                      ? selectedSource.url
                      : extractYouTubeVideoIds(urlInputValue)
                  )
                }
                style={{ height: "48px" }}
                disabled={
                  isTeacher ||
                  !enableChapterRegeneration ||
                  regeneratingChapter ||
                  !tableView ||
                  (sourceType === "course-content"
                    ? !selectedSource.url
                    : !urlInputValue)
                }
              >
                {regeneratingChapter ? (
                  <div className="d-flex align-items-center gap-2 ms-2">
                    <Spinner animation="border" size="sm" />
                    Loading
                  </div>
                ) : (
                  "Chapter Regenerations"
                )}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  publishVideo(!videoData.published);
                }}
                style={{ height: "48px" }}
                disabled={
                  (!videoData.published &&
                    !videoData.message.includes("ready for publish")) ||
                  publishingVideo
                }
              >
                {!videoData.published ? "Publish" : "Unpublish"}
              </Button>
            </div>
            <Row>
              <Col xs="12">
                {!!currentVideoCode && (
                  <ReactPlayer
                    ref={ref}
                    controls
                    url={`https://www.youtube.com/watch?v=${currentVideoCode}`}
                    width={"100%"}
                    playing={playing}
                    onPlay={() => {
                      if (!playing) {
                        clearTimeout(timeout.current);
                        setPlaying(true);
                      }
                    }}
                    onPause={() => {
                      if (playing) {
                        clearTimeout(timeout.current);
                        setPlaying(false);
                      }
                    }}
                    onProgress={({ playedSeconds }) => {
                      setVideoProgress(playedSeconds);
                    }}
                  />
                )}
              </Col>
            </Row>
            {tableView === "transcript" && (
              <TranscriptTable
                videoCode={currentVideoCode}
                scrollRef={scrollRef}
                onPlayClick={(startSeconds, endSeconds) => {
                  ref.current.seekTo(startSeconds, "seconds");
                  setPlaying(true);
                  clearTimeout(timeout.current);
                  timeout.current = setTimeout(() => {
                    setPlaying(false);
                  }, (endSeconds - startSeconds) * 1000);
                }}
                videoProgress={videoProgress}
                enableChapterRegeneration={() =>
                  setEnableChapterRegeneration(true)
                }
              />
            )}
            {tableView === "content" && (
              <ContentTable
                videoCode={currentVideoCode}
                scrollRef={scrollRef}
                onPlayClick={(startSeconds, endSeconds) => {
                  ref.current.seekTo(startSeconds, "seconds");
                  setPlaying(true);
                  clearTimeout(timeout.current);
                  timeout.current = setTimeout(() => {
                    setPlaying(false);
                  }, (endSeconds - startSeconds) * 1000);
                }}
                videoProgress={videoProgress}
                enableChapterRegeneration={() =>
                  setEnableChapterRegeneration(true)
                }
              />
            )}
            {showQuestionsModal && (
              <ViewQuestionsModal
                onClose={() => setShowQuestionsModal(false)}
                videoCode={
                  sourceType === "course-content"
                    ? selectedSource.url
                    : extractYouTubeVideoIds(urlInputValue)
                }
              />
            )}
            {showPromptsModal && (
              <EditPromptModal
                onClose={() => setPromptsModal(false)}
                videoCode={
                  sourceType === "course-content"
                    ? selectedSource.url
                    : extractYouTubeVideoIds(urlInputValue)
                }
                enableChapterRegeneration={() =>
                  setEnableChapterRegeneration(true)
                }
              />
            )}
          </>
        )}
      </Container>
    </>
  );
};

function extractYouTubeVideoIds(text) {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/g;
  const videoIds = [];
  let match;

  while ((match = regex.exec(text)) !== null) {
    videoIds.push(match[1]);
  }

  return videoIds;
}

function toVideoUrl(videoCode) {
  return `https://www.youtube.com/embed/${videoCode}`;
}

export default VideoGuideSettings;
